import { Form } from './Form';

import { CONTACT_TITLE, CONTACT_DESC } from '../../constants/strings';
export const Contact = () => {
  return (
    <div className='bg-[#F6F6F6] text-[#2E3440]'>
      <div className='mx-auto md:max-w-mid px-[30px] sm:px-[64px] py-10 pb-20'>
        <div className='flex flex-col items-center pb-[20px] px-2 md:w-full md:mx-auto'>
          <div className='pt-[25px] text-[40px] tracking-tight'>
            {CONTACT_TITLE}
          </div>
          <div className='md:w-5/6 text-center  pt-[16px] text-md'>
            {CONTACT_DESC}
          </div>
        </div>
        <Form />
      </div>
    </div>
  );
};
