import { KeyValue } from './types';

export const requestFormvValidations = {
  name: {
    required: {
      value: true,
      message: 'This field is required.',
    },
  },
  email: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'Please provide a valid email address.',
    },
  },
  carrier: {
    required: {
      value: true,
      message: 'This field is required.',
    },
  },
  date: {
    required: {
      value: true,
      message: 'This field is required.',
    },
  },
};

export type initialRequestFormTypes = {
  name: string;
  email: string;
  carrier: string;
  date: string | null;
  amount: string;
  poNumber: string;
  trailerNumber: string;
  truckNumber: string;
};

export const initialRequestFormValues: any = {
  name: '',
  email: '',
  carrier: '',
  date: null,
  amount: '',
  poNumber: '',
  trailerNumber: '',
  truckNumber: '',
};

export const RequestFormsItems: KeyValue = {
  name: 'Name',
  email: 'Email address',
  carrier: 'Carrier',
  poNumber: 'PO #',
  amount: 'Amount',
  date: 'Date of delivery',
  trailerNumber: 'Trailer #',
  truckNumber: 'Truck #',
};
