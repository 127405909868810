import { Link } from 'react-router-dom';
import { NAV_LINKS } from '../../constants/strings';

type MenuProps = {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  closeMenu: () => void;
};

export const Menu = ({ isOpen, setIsOpen, closeMenu }: MenuProps) => {
  return (
    <div
      className={`${
        isOpen ? 'relative md:hidden opacity-1' : 'hidden'
      } z-10 rounded divide-y divide-gray-100 border-none w-auto`}>
      <div className='flex flex-col justify-between h-screen w-auto pb-[8em]'>
        <ul className='py-1 text-lg font-semibold text-[#2C2F33]'>
          {NAV_LINKS.map(({ name, link }, i) => (
            <Link
              key={i}
              to={`${name === 'Receipt Request' ? `${link}` : `/#${link}`} `}>
              <li
                key={i}
                className=' py-[1rem] border-b-gray-400 border-b-[1px]'
                onClick={() => closeMenu()}>
                {name}
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};
