import { HEADER_TITLE, HEADER_DESC } from '../../constants/strings';

export const Header = () => {
  return (
    <>
      <div id='/' className='text-[#5865F2] bg-[#F6F6F6]'>
        <div className='mx-auto md:max-wa-max md:mx-auto pt-[40px] md:pt-[85px] lg:pt-[70px] px-[30px] sm:px-[64px] pb-[120px]'>
          <div className='flex flex-col lg:flex-row items-start justify-between lg:max-w-max mx-auto py-10'>
            <div className='w-full lg:w-3/4 flex flex-col text-left tracking-tight '>
              <h1 className='lg:w-[85%] text-[45px] sm:text-[50px] lg:text-[60px] xl:text-[4rem] tracking-tight leading-[1.1428571429] font-thin'>
                {HEADER_TITLE}
              </h1>
              <div className='lg:w-3/4 text-xl lg:text-xl text-black leading-normal pt-6'>
                {HEADER_DESC}
              </div>
            </div>

            <div className='w-full lg:w-1/2 pt-16 md:px-0 lg:pt-0'>
              <img
                className='max-w-full lg:max-w-none max-h-[500px] md:overflow-hidden pointer-events-none rounded-lg shadow-xl'
                src='./assets/images/trucks.jpg'
                alt='headerHero'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
