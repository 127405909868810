type ShowcaseItemProps = {
  title: string;
  header: string;
  description: string;
  image: string;
  list?: string[];
  index: number;
};

export const ShowcaseItem = ({
  title,
  header,
  description,
  image,
  list,
  index,
}: ShowcaseItemProps) => {
  const isEven = index % 2 === 0;

  return (
    <div className='py-6 md:py-16 grid md:grid-cols-2 gap-24'>
      <div
        className={`pb-5 flex flex-col justify-center order-last ${
          isEven ? 'md:order-last' : 'md:order-first lg:ml-20'
        }`}>
        <div className='w-full flex items-center text-2xl lg:text-3xl leading-normal font-normal'>
          <h2 className='pt-4 text-bloo'>{title}</h2>
        </div>
        <h3 className='text-[2rem] md:text-[2.625rem] pt-2 leading-snug font-extralight text-breen'>
          {header}
        </h3>
        <div className='pt-4 text-xl'>{description}</div>
        <div className='pt-4'>
          {list?.map((item, i) => (
            <div key={i} className='flex items-center py-2'>
              <svg
                className='mx-2'
                xmlns='http://www.w3.org/2000/svg'
                fill='#3b82f6'
                width='20'
                height='20'
                viewBox='0 0 24 24'>
                <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z' />
              </svg>
              <div>{item}</div>
            </div>
          ))}
        </div>
      </div>

      <div
        className={`rounded shadow-[3px_3px_10px_1px_rgba(0,0,0,0.25),-3px_-3px_12px_2px_rgba(0,0,0,0.22)] -mb-20 md:mb-0 ${
          isEven ? 'md:-mr-3' : 'md:-ml-3'
        }`}>
        <img
          className='w-full h-full object-cover object-center rounded pointer-events-none'
          src={image}
          alt='sectionHero'
        />
      </div>
    </div>
  );
};
