import { CONTACT_INFO } from '../../constants/strings';

export const Footer = () => {
  const { address, stateZip, phoneNumber, email } = CONTACT_INFO;
  return (
    <>
      <footer className='bg-white mx-auto md:max-wa-max md:mx-auto py-[45px] lg:py-[70px] px-[30px] sm:px-[64px]'>
        <div className='md:flex md:justify-between lg:max-w-max mx-auto tracking-wider leading-relaxed'>
          <div className='mb-6 md:mb-0'>
            <a href='/' className='flex items-center'>
              <img
                src='/assets/images/logo.png'
                className='mr-3 h-8'
                alt='PACIFIC'
              />
            </a>
          </div>

          <div className='grid grid-cols-1'>
            <div className='order-2 text-left md:text-right text-[#2E3440]'>
              <div className=''>{address} </div>
              <div className=''>{stateZip} </div>
              <div className='pt-2'>{phoneNumber}</div>
              <div className='pt-2'>{email}</div>
            </div>
          </div>
        </div>
        <div className='lg:max-w-max mx-auto'>
          <hr className='my-6 border-gray-200' />
          <span className='block text-sm text-[#2E3440] text-left'>
            © 2022{' '}
            <a href='/' className='hover:underline'>
              Pacific DSS, Inc™
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </>
  );
};
