import classnames from 'classnames';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type FormItemProps = {
  name: string;
  label: string;
  value: any;
  error: string;
  placeholder?: string;
  onDatePick: (date: string) => void;
  handleChange: (e: any) => void;
};

export const FormItem = ({
  name,
  label,
  value,
  error,
  placeholder,
  onDatePick,
  handleChange,
}: FormItemProps) => {
  const classname = classnames(
    'relative z-1 shadow-sm border z-2 rounded p-2.5 px-3 mb-3 focus:outline-1 focus:bg-white focus:outline-blue-600',
    {
      'border-gray-500': !error,
      'border-error': !!error,
    }
  );

  return (
    <div className='w-full md:w-1/2 h-full px-2 '>
      <div className='flex flex-col flex-start mt-4 mb-2'>
        <label
          className='mb-3 uppercase text-xs tracking-wider text-gray-700 text-md first-line:font-bold'
          htmlFor={name}>
          {label}
        </label>
        {name !== 'date' ? (
          <input
            className={classname}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(e) => handleChange(e)}
          />
        ) : (
          <ReactDatePicker
            className={`w-full ${classname}`}
            selected={value === null ? null : new Date(value)}
            onChange={(date: Date) => onDatePick(date.toLocaleDateString())}
	    maxDate={new Date()}
          />
        )}
        {error && (
          <span className='-my-4 py-1.5 text-error text-sm'>{error}</span>
        )}
      </div>
    </div>
  );
};
