//nav
export const NAV_LINKS = [
  {
    name: 'About',
    link: 'about',
  },
  {
    name: 'Technology',
    link: 'technology',
  },
  {
    name: 'Receipt Request',
    link: 'receipt-request',
  },
];
export const LOG_IN = 'Log In';
export const SIGN_UP = 'Sign Up';

//header
export const HEADER_TITLE =
  'Lower your operational cost with result-oriented solutions';
export const HEADER_DESC =
  'Pacific DSS Inc. is a company that provides efficient and cost effective results for your distribution center’s unloading operation through our team culture and competitive pricing solutions.';

// contact
export const CONTACT_TITLE = 'Send us a Receipt Request';
export const CONTACT_DESC = `For all receipt requests, we're open from Monday through Friday Pacific
time. For any messages received during our off hours, we will reply
when normal business hours resume.`;

// about
export const ABOUT_TITLE =
  "We're on a mission to provide support for our clients by enabling efficiency at scale.";
export const ABOUT_DESC =
  'Distribution Centers deliver a variety of products to people and businesses all over the world. To achieve this goal, cost and efficiency is essential for distribution centers to provide these products at an affordable price. At Pacific DSS Inc., our business is to work with our clients and partners to improve efficiency on the dock and lower distribution operational costs.';
export const ABOUT_COMPANY =
  "Pacific DSS Inc., established in 2017, provides distribution support services specializing in the unloading phase of the operation. Our key members have over a decade of experience in this field and have made a positive impact for our client's operation. We strive to ensure client satisfaction by providing efficient and cost effective solutions by establishing, maintaining, and improving on our high performance team culture, utilizing responsive technology that provide dock visibility to querying various reports, and offering competitive rates within the industry. Our result-oriented solutions improve dock efficiency and faster unloading turnaround.";

// showcase
export const SHOWCASE_DATA = [
  {
    title: 'Loadtrak',
    header: 'Track the entire process',
    description:
      'Loadtrak is our ever-evolving proprietary cloud-based software that tracks and records hundreds of key data points throughout our operation. These data points provide multitude of benefits that include:',
    list: [
      'Real time dock view activity',
      'Load complaince',
      'KPI tracking',
      'Customizeable reports',
    ],
    image: './assets/images/loadtrak.jpg',
  },
  {
    title: 'Relay',
    header: 'A better way to pay',
    description:
      'Pacific DSS Inc. has partnered with Relay payments to provide a more effective and efficient solution for payment processing, enhancing driver’s experience at the distribution center.',
    list: [
      'Checkless and cardless payment processing',
      'Cloud-based',
      'Quicker transactions',
    ],
    image: './assets/images/payment.jpg',
  },
];
export const CONTACT_INFO = {
  address: '225 W. Hospitality Lane Suite 201i',
  stateZip: 'San Bernardino, CA 92408',
  phoneNumber: '(909) 538-6888',
  email: 'office@pacificdss.com',
};
