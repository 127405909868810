import { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { FormItem } from './FormItem';
import 'react-toastify/dist/ReactToastify.css';

import {
  requestFormvValidations,
  initialRequestFormValues,
  RequestFormsItems,
} from '../../constants/constants';
import { RequestForm, FormErrors, Validation } from '../../constants/types';

export const Form = () => {
  const [data, setData] = useState(initialRequestFormValues || {});
  const [errors, setErrors] = useState<FormErrors>({});
  const [isDisabled, setIsDisabled] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const onDatePick = (date: string) => setData({ ...data, date: date });

  const isValidated = (data: { [key: string]: string }) => {
    const validations: { [key: string]: Validation } = requestFormvValidations;

    if (validations) {
      let valid = true;
      const newErrors: FormErrors = {};

      for (const key in validations) {
        const value = data[key];
        const validation = validations[key];

        const pattern = validation?.pattern;
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false;
          newErrors[key] = pattern.message;
        }

        const custom = validation?.custom;
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false;
          newErrors[key] = custom.message;
        }

        const required = validation?.required;
        if (required?.value && !value) {
          valid = false;
          newErrors[key] = required?.message;
        }
      }

      if (!valid) {
        setErrors(newErrors);
      }

      return valid;
    }
  };

  const onSubmit = async () => {
    if (!isValidated(data)) {
      return;
    }
    setIsDisabled(true);
    try {
      await toast.promise(
        axios.post(
          `${
            process.env.REACT_APP_NODE_ENV !== 'production'
              ? process.env.REACT_APP_SMTP_SERVER_LOCAL_HOST
              : process.env.REACT_APP_SMTP_SERVER_PROD_HOST
          }/api/ticket-request`,
          data
        ),
        {
          pending: 'Sending...',
          success: 'Email Sent!',
          error: 'Awww, something went wrong.',
        }
      );

      setData(initialRequestFormValues);
      setErrors({});
      setIsDisabled(false);
    } catch (err) {
	
}
  };

   return (
    <>
      <ToastContainer
        position='top-left'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className='top-20 '
      />
      <form
        className='flex flex-wrap items-center justify-center pt-10'
        onSubmit={onSubmit}>
        {Object.keys(initialRequestFormValues).map((value, i) => (
          <FormItem
            key={i}
            name={value}
            value={data[value]}
            onDatePick={onDatePick}
            label={RequestFormsItems[value as keyof RequestForm]}
            handleChange={(e) => onChange(e)}
            error={errors[value]}
          />
        ))}
      </form>

      <div className='flex justify-center my-6 px-2'>
        <button
          type='submit'
          disabled={isDisabled}
          className='bg-blue-500 hover:bg-blue-700 w-full md:w-1/2 text-white font-bold py-2 px-4 rounded '
          onClick={onSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};
