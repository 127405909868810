import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { Menu } from './Menu';
import { Hamburger } from '../../global/Hamburger';
import { NAV_LINKS } from '../../constants/strings';

export const Nav = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => {
    document.body.style.overflow = isOpen ? '' : 'hidden';
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const listener = () => {
      if (window.innerWidth > 768) {
        document.body.style.overflow = '';
        setIsOpen(false);
      }
    };
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    const listener = () => {
      if (window && window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  const classname = classnames(
    'w-full z-50 md:mx-auto py-[16px] px-[30px] sm:px-[64px] transition-shadow duration-500',
    {
      'bg-white text-[#2C2F33]s sticky top-0 left-0 shadow-lg': isSticky,
    }
  );

  return (
    <>
      <nav id='nav' className={classname}>
        <div className='lg:max-w-max flex items-center justify-between mx-auto'>
          <Link to={'/'}>
            <div onClick={() => (isOpen ? closeMenu() : null)} className='py-2'>
              <img className='h-7' src='/assets/images/logo.png' alt='' />
            </div>
          </Link>

          <div className='hidden md:flex flex-2 ml-8 leading-relaxed'>
            {NAV_LINKS.map(({ name, link }, i) => (
              <Link
                key={i}
                to={`${name === 'Receipt Request' ? `${link}` : `/#${link}`} `}>
                <li
                  key={i}
                  className='block list-none text-[18px] px-5 last:pr-0'>
                  {name}
                </li>
              </Link>
            ))}
          </div>
          <button className='flex md:hidden' onClick={closeMenu}>
            <Hamburger isOpen={isOpen} />
          </button>
        </div>
        {<Menu isOpen={isOpen} setIsOpen={setIsOpen} closeMenu={closeMenu} />}
      </nav>
    </>
  );
};
