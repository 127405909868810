type HamburgerProps = {
  isOpen: boolean;
};

export const Hamburger = ({ isOpen }: HamburgerProps) => {
  return (
    <div>
      <span
        className={`bg-[#2C2F33] line transition-all ease-in-out duration-300 ${
          isOpen ? 'translate-y-[7px] rotate-45' : 'translate-y-0 rotate-0'
        }`}></span>
      <span
        className={`bg-[#2C2F33] line transition-all ease-in-out duration-300 ${
          isOpen ? 'opacity-0' : 'opacity-100'
        }`}></span>
      <span
        className={`bg-[#2C2F33] line transition-all ease-in-out duration-300 ${
          isOpen ? '-translate-y-[7px] -rotate-45' : 'translate-y-0 rotate-0'
        }`}></span>
    </div>
  );
};
