import { Header } from './Header';
import { About } from './About';
import { Showcase } from './Showcase';

export const Landing = () => {
  return (
    <>
      <Header />
      <About />
      <Showcase />
    </>
  );
};
