import { ShowcaseItem } from './ShowcaseItem';
import { SHOWCASE_DATA } from '../../constants/strings';

export const Showcase = () => {
  return (
    <>
      <div
        id='technology'
        className='bg-[#F6F6F6] mx-auto md:max-wa-max md:mx-auto py-28 px-[30px] sm:px-[64px]'>
        <div className='flex flex-col items-center justify-between lg:max-w-max mx-auto'>
          {SHOWCASE_DATA.map(
            ({ title, header, description, image, list }, index) => (
              <ShowcaseItem
                key={index}
                title={title}
                header={header}
                description={description}
                image={image}
                list={list}
                index={index}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};
