import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ScrollToTop } from './global/ScrollToTop';
import { Nav } from './components/Nav';
import { Landing } from './components/Landing';
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';

import './App.css';

export const App = () => {
  return (
    <>
      <ScrollToTop />
      <Nav />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/receipt-request' element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
};
