import {
  ABOUT_TITLE,
  ABOUT_DESC,
  ABOUT_COMPANY,
} from '../../constants/strings';

export const About = () => {
  return (
    <>
      <div id='about' className='py-24 md:py-[6rem] bg-white'>
        <div className='mx-auto md:max-wa-max md:mx-auto pt-[40px] px-[30px] sm:px-[64px] md:px-[40px]'>
          <div className='flex flex-col items-center justify-center lg:max-w-max mx-auto'>
            <div className='text-3xl text-left md:w-3/4 xl:w-3/4 text-[#404EED] md:text-4xl lg:text-4xl leading-[1.1428571429] tracking-tight sm:text-center font-500'>
              {ABOUT_TITLE}
            </div>
          </div>
        </div>
        <div className='flex flex-col xl:flex-row items-center justify-center xl:max-w-max mx-auto text-center py-16 pb-[40px] px-[30px] sm:px-[64px] xl:px-0'>
          <img
            className='w-full md:w-3/4 xl:w-1/2 rounded shadow-lg xl:order-2 max-h-[625px] pointer-events-none mb-10'
            src='./assets/images/aboutus.jpg'
            alt='aboutus'
          />
          <div className='w-full md:w-3/4  text-left pt-6 lg:pr-10 '>
            <div className='text-[#23272A] text-md tracking-normal leading-normal pb-3'>
              {ABOUT_DESC}
            </div>
            <div className='text-[#23272A] text-md  tracking-normal leading-normal py-3'>
              {ABOUT_COMPANY}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
